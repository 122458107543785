const { render, useState } = wp.element;
import { Formik, Form, Field } from 'formik';
import * as _ from 'lodash';

const DomainRegistrar = () => {
  const subscriptionId = 120;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domain, setDomain] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [price, setPrice] = useState(undefined);

  const searchDomain = async (query) => {
    const res = await fetch('/wp-json/getasite/domains/v1/search', {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query })
    });

    return res.json();
  };

  const fetchDomainStatus = async (domain) => {
    const res = await fetch('/wp-json/getasite/domains/v1/price', {
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ domain })
    });

    return res.json();
  };

  /**
   * Signup Form Email
   * @returns {JSX.Element}
   */
  const signupForm = () => {
    return (
      <Formik
        initialValues={{ email: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.search = 'Oops! We need your email to create the Account';
          }

          return errors;
        }}
        onSubmit={async ({ email }, { setSubmitting }) => {
          // Set Loading to True
          setLoading(true);

          // Send a POST to Create the Account and Attach to that user the Desired Domain
          const res = await fetch(`/wp-json/getasite/users/v1/register`, {
            method: 'POST',
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, domain })
          });

          // Get the Result
          const result = await res.json();

          // Set Message from the Server
          setMessage(result.message);

          // Redirect to the Cart
          setTimeout(() => {
            window.location.replace(`/checkout?add-to-cart=${subscriptionId}&email=${email}`);
          }, 3000);
        }}>
        {({ isSubmitting, errors }) => (
          <Form className={'row mt-4'}>
            <div className={`col-12 mb-3 ${errors.search ? 'has-validation' : ''}`}>
              <Field name={'email'}>
                {({ field, meta }) => (
                  <>
                    <input
                      type="email"
                      name="email"
                      className={`form-control form-control-lg ${
                        meta.touched && meta.error ? 'is-invalid' : ''
                      }`}
                      placeholder={'Email Address'}
                      required
                      disabled={loading}
                      {...field}
                    />
                    {meta.touched && meta.error && <div className="tooltip">{meta.error}</div>}
                  </>
                )}
              </Field>
            </div>
            <div className="col-12">
              <button type="submit" disabled={isSubmitting} className={'btn btn-primary'}>
                {loading && price ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>{' '}
                    Creating Account...
                  </>
                ) : (
                  'Get Domain'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  const renderOptions = () => {
    // For each Domain on the List
    return _.map(options, (opt) => {
      // Domain Status
      const status = opt?.status === 'active' ? 'text-danger' : 'text-success';

      return (
        <button
          type={'button'}
          key={opt.domain}
          className={`list-group-item list-group-item-action btn-lg ${
            status === 'active' ? 'disabled' : ''
          }`}
          onClick={async () => {
            // Set the Domain
            setDomain(opt.domain);

            setLoading(true);

            // fetch the Domain Status
            const res = await fetchDomainStatus(opt.domain);
            setPrice(res.registration);
            setLoading(false);
          }}
          disabled={opt.status === 'active'}>
          <div className="d-flex align-items-center position-relative">
            <i className={`fa-solid fa-circle fa-2xs ${status}`}></i>{' '}
            <span className={'ms-2 fw-bold'}>{opt.domain}</span>
            <span
              className={`small fw-bold position-absolute top-50 end-0 translate-middle-y ${
                opt.status === 'active' ? 'text-danger' : 'text-success'
              }`}>
              {opt.status === 'active' ? 'Unavailable' : 'Available'}
            </span>
          </div>
        </button>
      );
    });
  };

  return (
    <div className={'my-3'}>
      <Formik
        initialValues={{ search: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.search) {
            errors.search = 'Oops! Search Fields cannot be empty';
          }

          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          // Set Loading to True
          setLoading(true);

          // Search for domains
          const domains = await searchDomain(values.search);

          // Order the Domains in order of Price
          setOptions(_.orderBy(domains, ['status'], ['desc']));

          setSubmitting(false);
          setLoading(false);

          const modal = new understrap.Modal(document.querySelector('#domainsModal'), {
            backdrop: false
          });

          modal.show();
        }}>
        {({ isSubmitting, errors }) => (
          <Form>
            <div className={`input-group ${errors.search ? 'has-validation' : ''}`}>
              <Field name={'search'}>
                {({ field, meta }) => (
                  <>
                    <input
                      type="search"
                      name="search"
                      className={`form-control form-control-lg ${
                        meta.touched && meta.error ? 'is-invalid' : ''
                      }`}
                      placeholder={'Find the Perfect Domain...'}
                      required
                      {...field}
                    />
                    {meta.touched && meta.error && <div className="tooltip">{meta.error}</div>}
                  </>
                )}
              </Field>
              <button
                type="submit"
                disabled={isSubmitting}
                className={'btn btn-primary btn-lg'}
                style={{
                  width: 110
                }}>
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                    <span className="visually-hidden">Loading...</span>
                  </>
                ) : (
                  'Search'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <div className="modal fade" tabIndex="-1" id={'domainsModal'}>
        <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">
                🚀 Hooray! We Found Some Domains that you might like
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setDomain(undefined);
                  setPrice(undefined);
                }}></button>
            </div>
            <div className="modal-body">
              <div className={'row align-items-xl-center'}>
                <div className="col-12 col-md-7">
                  <p className={'text-body'}>
                    We Found <span className={'fw-bold fs-5'}>{options.length}</span> domains for
                    you to check:
                  </p>
                  <div className="p-3 domain-list-wrapper border border-4">
                    <ul className="list-group">{renderOptions()}</ul>
                  </div>
                </div>
                <div className="col-12 col-md-5">
                  {domain && (
                    <>
                      <p className={'text-body'}>
                        <strong>You Choose:</strong> <br />
                      </p>
                      <h4 className={'text-success position-relative'}>
                        <i className="fa-solid fa-check-double"></i> {domain}
                        {loading ? (
                          <span
                            className="ms-4 spinner-border spinner-border-sm text-success"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <span
                            className={`small fw-bold position-absolute top-50 end-0 translate-middle-y text-success fs-4`}>
                            {price > 20
                              ? `$${(price / 2).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumSignificantDigits: 2
                                })}`
                              : 'Free'}
                          </span>
                        )}
                      </h4>
                      {signupForm()}
                      {message && (
                        <div className="row mt-3">
                          <div className="col-12">
                            <div className="alert alert-success" role="alert">
                              {message}{' '}
                              <div>
                                Taking you to Checkout! Hang Tight{' '}
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

if (document.getElementById('getasite-domain-registrar-block')) {
  render(<DomainRegistrar />, document.getElementById('getasite-domain-registrar-block'));
}
